import styled from 'styled-components';
import { Header2 } from '../../atoms/Fonts';

export const LogoWithTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled(Header2)`
  color: ${(props) => props.theme.colors.text.dark};
`;
