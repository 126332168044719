import React from 'react';
import { SizeName, SizeStyles } from '../../../types/buttonStyle';
import { ButtonContainer, ButtonText } from './styled';

//function that  take any number of arguments of any type and return a value of any type.
type OnClickWithArgsType = (...args: any[]) => any;
type OnClickType = () => void;

interface ButtonProps {
  /**
   * Size of the Button
   */
  size: SizeName;
  /**
   * Text content of the button
   */
  children?: React.ReactNode;
  /**
   * Disable status of button
   */
  disabled?: boolean;
  /**
   * Variant type of the button
   */
  variant: 'primary' | 'outline' | 'subtle' | 'link';
  /**
   *  when block mode is true, the button will occupy the whole width of the div parent
   */
  block?: boolean;
  /**
   *  onClick handler
   */
  onClick?: OnClickType | OnClickWithArgsType;
  /**
   *  set the button type
   */
  type?: 'button' | 'submit' | 'reset' | undefined;

  backgroundColor?: string;
  hoverBackgroundColor?: string;
}

//In order to have code completion on the keys here, we create a Record<T,K> type referenced in /types/buttonStyle.ts
const buttonStyles: SizeStyles = {
  xs: {
    height: 32,
    width: 'fit-content',
  },
  sm: {
    height: 40,
    width: 'fit-content',
  },
  md: {
    height: 48,
    width: 'fit-content',
  },
  lg: {
    height: 56,
    width: 'fit-content',
  },
};

/**
 *  Button Component with custom sizes and variants
 *
 */
const Button = (props: ButtonProps) => {
  const {
    type = 'button',
    onClick,
    size,
    disabled,
    children,
    variant,
    block,
    ...rest
  } = props;

  return (
    <ButtonContainer
      type={type}
      onClick={onClick}
      style={buttonStyles[size]}
      variant={variant}
      disabled={disabled}
      block={block}
      backgroundColor={props.backgroundColor}
      hoverBackgroundColor={props.hoverBackgroundColor}
      {...rest}
    >
      <ButtonText size={size}>{children}</ButtonText>
    </ButtonContainer>
  );
};

export default Button;
