import { useState } from 'react';
import { useTheme } from 'styled-components';
import { FieldSize } from '../../atoms/TextInput';
import TextFieldWithIcon from '../../molecules/TextFieldWithIcon';
import { PasswordFieldContainer } from './PasswordField.styled';

interface PasswordFieldProps {
  size: FieldSize;
  name: string;
  placeholder?: string;
  /*We allow the parent to handle the enabled state if needed*/
  onIconClick?: () => void;
  isEnabled?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const PasswordField = (props: PasswordFieldProps) => {
  const [enabled, setEnabled] = useState(false);
  const theme = useTheme();

  return (
    <PasswordFieldContainer>
      <TextFieldWithIcon
        name={props.name}
        size={props.size}
        placeholder={props.placeholder}
        onIconClick={() =>
          props.onIconClick ? props.onIconClick() : setEnabled(!enabled)
        }
        type={enabled || props.isEnabled ? 'text' : 'password'}
        withIcon='eye'
        withIconSide='right'
        withIconFill={
          props.isEnabled || enabled
            ? theme.colors.text.primary
            : theme.colors.text.light
        }
        errorMessage={props.errorMessage}
      />
    </PasswordFieldContainer>
  );
};

export default PasswordField;
