import styled from 'styled-components';

export const TextFieldWithIconContainer = styled.div`
  display: flex;
  position: relative;
`;

export const IconWrapper = styled.div<{ iconSide?: 'left' | 'right' }>`
  position: absolute;
  z-index: 200;

  display: flex;
  align-items: center;

  ${(props) => (props.iconSide === 'left' ? ` left: 16px` : null)};
  ${(props) => (props.iconSide === 'right' ? ` right: 16px` : null)};

  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;
