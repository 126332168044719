import { Field, FieldProps } from 'formik';
import { useTheme } from 'styled-components';
import Icon, { IconName } from '../../atoms/Icon';
import TextField, {
  FieldSize,
  TextFieldProps,
  textInputHeights,
} from '../../atoms/TextInput';
import {
  IconWrapper,
  TextFieldWithIconContainer,
} from './TextFieldWithIcon.styled';

interface TextFieldWithIconProps {
  withIcon?: IconName;
  withIconFill?: `#${string}`;
  withIconSide?: 'left' | 'right';
  onIconClick?: () => void;
  name: string;
  size: FieldSize;
  placeholder?: string;
  type?: string;
  errorMessage?: string;
  defaultValue?: string;
  withoutFormik?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
  value?: string;
}

type FieldStyles = Record<
  FieldSize,
  { icon: { width: number; height: number } }
>;

const styles: FieldStyles = {
  sm: { icon: { width: 16, height: 16 } },
  md: { icon: { width: 24, height: 24 } },
  lg: { icon: { width: 24, height: 24 } },
};

const TextFieldWithIcon = (props: TextFieldWithIconProps) => {
  const theme = useTheme();

  const textFieldProps: TextFieldProps = {
    name: props.name,
    withoutFormik: props.withoutFormik,
    size: props.size,
    placeholder: props.placeholder,
    paddingRight:
      props.withIconSide === 'right'
        ? styles[props.size].icon.width + (30 + 16)
        : 30,
    paddingLeft:
      props.withIconSide === 'left'
        ? styles[props.size].icon.width + (30 + 16)
        : 30,
    errorMessage: props.errorMessage,
    type: props.type ?? 'text',
    onChange: props.onChange,
    defaultValue: props.defaultValue,
  };

  return (
    <TextFieldWithIconContainer>
      {props.withIcon && props.withIconSide == 'right' ? (
        <IconWrapper
          iconSide='right'
          onClick={props.onIconClick}
          //match the height of the text input so the icon can be centered vertically
          style={{ height: textInputHeights[props.size].container.height }}
        >
          <Icon
            name={props.withIcon}
            size={styles[props.size].icon.width}
            color={props.withIconFill ?? theme.colors.text.light}
          />
        </IconWrapper>
      ) : null}
      {props.withoutFormik ? (
        <TextField {...textFieldProps} />
      ) : (
        <Field name={props.name}>
          {(field: FieldProps) => (
            <TextField {...textFieldProps} disabled={props.disabled} />
          )}
        </Field>
      )}
      {props.withIcon && props.withIconSide == 'left' ? (
        <IconWrapper
          iconSide='left'
          onClick={props.onIconClick} //match the height of the text input so the icon can be centered vertically
          style={{ height: textInputHeights[props.size].container.height }}
        >
          <Icon
            name={props.withIcon}
            iconProps={styles[props.size].icon}
            size={styles[props.size].icon.width}
            color={props.withIconFill ?? theme.colors.text.light}
          />
        </IconWrapper>
      ) : null}
    </TextFieldWithIconContainer>
  );
};

export default TextFieldWithIcon;
