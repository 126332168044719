import { StaticImageData } from 'next/image';
import styled from 'styled-components';
import { device } from '../../styles/breakpoints';

interface SideBannerContainerProps {
  // image: StaticImageData;
  maxImageWidth: number | string;
}

const SideBannerContainer = styled.div<SideBannerContainerProps>`
  background-size: cover;
  background-position: left;
  flex: 0 1 ${(props) => props.maxImageWidth}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.laptop} {
    display: none;
  }
`;

export default SideBannerContainer;
