import Image from 'next/image';
import { LogoWithTitleContainer, LogoWrapper, Title } from './styled';

// import logo from '/public/assets/images/logo/logo.png';

interface LogoWithTitleProps {
  title: string;
  gap?: number | string;
}

const LogoWithTitle = (props: LogoWithTitleProps) => {
  return (
    <LogoWithTitleContainer>
      <LogoWrapper style={props.gap ? { marginBottom: props.gap } : {}}>
        {/* <Image src={logo} width={158} height={40} alt='logo' /> */}
        <img
          src={'https://public.dealerportal.eyeforce.com/logo.png'}
          alt='Eyeforce'
          style={{ width: 'auto', height: 50 }}
        />
      </LogoWrapper>
      <Title>{props.title}</Title>
    </LogoWithTitleContainer>
  );
};

export default LogoWithTitle;
