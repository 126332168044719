import styled from 'styled-components';
import { device } from '../../styles/breakpoints';

interface SideBannerContentContainerProps {}

const SideBannerContentContainer = styled.div<SideBannerContentContainerProps>`
  flex: 1 0 500px;
  height: 100%;
  display: flex;

  overflow-y: auto;

  @media ${device.mobile} {
    flex: 1;
    overflow-y: initial;
  }
`;

export default SideBannerContentContainer;
