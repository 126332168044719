import styled from 'styled-components';
import { css } from 'styled-components';
import { SizeName } from '../../../types/buttonStyle';

interface ButtonContainerProps {
  disabled: boolean | undefined;
  variant: 'primary' | 'outline' | 'subtle' | 'link';
  block: boolean | undefined;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
}

interface ButtonLabelProps {
  size: SizeName;
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: grid;
  place-content: center;
  padding: 6px 12px;

  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.theme.primary.default};
  color: ${(props) => props.theme.colors.text.white};
  border: none;
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.colors.theme.primary.dark};
  }

  //when disabled is activated
  ${(props) =>
    props.disabled
      ? css`
          pointer-events: none;
          background: ${(props) => props.theme.colors.theme.primary.soft};
        `
      : null}

  //if outline variant is selected
  ${(props) =>
    props.variant === 'outline'
      ? css`
          background-color: transparent;
          color: ${(props) => props.theme.colors.text.default};
          border: 2px solid #e9ecef;

          &:hover {
            background-color: transparent;
            color: ${(props) => props.theme.colors.text.primary};
          }
        `
      : null}

  //when disabled is activated and outline
  ${(props) =>
    props.disabled && props.variant === 'outline'
      ? css`
          pointer-events: none;
          opacity: 0.3;
        `
      : null}

  //if subtle variant is selected
  ${(props) =>
    props.variant === 'subtle'
      ? css`
          background-color: transparent;
          color: ${(props) => props.theme.colors.text.default};
          border: none;
          &:hover {
            background: ${(props) =>
              props.theme.colors.theme.neutralLight.default};
          }
        `
      : null}

  //when disabled is activated and outline
  ${(props) =>
    props.disabled && props.variant === 'subtle'
      ? css`
          pointer-events: none;
          opacity: 0.3;
        `
      : null}

  
  //if link variant is selected
  ${(props) =>
    props.variant === 'link'
      ? css`
          padding: 0;
          height: revert !important;
          background-color: transparent;
          color: ${(props) => props.theme.colors.text.primary};
          border: none;

          &:hover {
            background-color: transparent;
            text-decoration: underline;
          }
        `
      : null}

  //when disabled is activated and link
  ${(props) =>
    props.disabled && props.variant === 'link'
      ? css`
          pointer-events: none;
          opacity: 0.3;
        `
      : null}

  /* toggle block mode */
  ${(props) =>
    props.block
      ? css`
          width: 100% !important;
        `
      : null}
`;

export const ButtonText = styled.span<ButtonLabelProps>`
  cursor: pointer;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  font-family: 'PT Sans', sans-serif;

  //xs and md font size
  ${(props) =>
    props.size === 'xs' || props.size === 'sm'
      ? css`
          font-size: 14px;
        `
      : null}

  // md and lg font size
  ${(props) =>
    props.size === 'md' || props.size === 'lg'
      ? css`
          font-size: 16px;
        `
      : null};

  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`;
